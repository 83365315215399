
@import "./component-variables.scss";

.dropdown {
    .btn:not(.btn-sm):not(.btn-lg),
    .btn:not(.btn-sm):not(.btn-lg).dropdown-toggle {
        padding: $dropdown-btn-padding-y $dropdown-btn-padding-x;
    }


    .dropdown-menu {
        .dropdown-item {
            padding: 0.714rem 1.428rem;
        }
    }


    .dropdown-toggle {
        &:after,
        &:before {
            vertical-align: middle;
        }
    }
}

.dropdown {
    &.no-arrow{
        .dropdown-toggle {
            &:after,
            &:before {
                display: none;
            }
        }
    }
}