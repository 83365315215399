@font-face {
	font-family: 'Gilroy';
	src:  url('../fonts/Gilroy/Gilroy-ExtraBold.woff2') format('woff2'),
		  url('../fonts/Gilroy/Gilroy-ExtraBold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy';
	src: 	url('../fonts/Gilroy/Gilroy-UltraLightItalic.woff2') format('woff2'),
			url('../fonts/Gilroy/Gilroy-UltraLightItalic.woff') format('woff');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Gilroy';
	src: 	url('../fonts/Gilroy/Gilroy-BoldItalic.woff2') format('woff2'),
			url('../fonts/Gilroy/Gilroy-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Gilroy';
	src: 	url('../fonts/Gilroy/Gilroy-SemiBold.woff2') format('woff2'),
			url('../fonts/Gilroy/Gilroy-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy';
	src: 	url('../fonts/Gilroy/Gilroy-BlackItalic.woff2') format('woff2'),
			url('../fonts/Gilroy/Gilroy-BlackItalic.woff') format('woff');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Gilroy';
	src: 	url('../fonts/Gilroy/Gilroy-ThinItalic.woff2') format('woff2'),
			url('../fonts/Gilroy/Gilroy-ThinItalic.woff') format('woff');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Gilroy';
	src: 	url('../fonts/Gilroy/Gilroy-ExtraBoldItalic.woff2') format('woff2'),
			url('../fonts/Gilroy/Gilroy-ExtraBoldItalic.woff') format('woff');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Gilroy';
	src: 	url('../fonts/Gilroy/Gilroy-Black.woff2') format('woff2'),
			url('../fonts/Gilroy/Gilroy-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy';
	src: 	url('../fonts/Gilroy/Gilroy-HeavyItalic.woff2') format('woff2'),
			url('../fonts/Gilroy/Gilroy-HeavyItalic.woff') format('woff');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Gilroy';
	src: 	url('../fonts/Gilroy/Gilroy-Regular.woff2') format('woff2'),
			url('../fonts/Gilroy/Gilroy-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

// @font-face {
// 	font-family: 'Gilroy';
// 	src: 	url('../fonts/Gilroy/Gilroy-SemiboldItalic.woff') format('woff2'),
// 			url('../fonts/Gilroy/Gilroy-SemiboldItalic.woff') format('woff');
// 	font-weight: 600;
// 	font-style: italic;
// }

@font-face {
	font-family: 'Gilroy';
	src: 	url('../fonts/Gilroy/Gilroy-Medium.woff2') format('woff2'),
			url('../fonts/Gilroy/Gilroy-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy';
	src: 	url('../fonts/Gilroy/Gilroy-Bold.woff2') format('woff2'),
			url('../fonts/Gilroy/Gilroy-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy';
	src: 	url('../fonts/Gilroy/Gilroy-RegularItalic.woff2') format('woff2'),
			url('../fonts/Gilroy/Gilroy-RegularItalic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Gilroy';
	src: 	url('../fonts/Gilroy/Gilroy-Light.woff2') format('woff2'),
			url('../fonts/Gilroy/Gilroy-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy';
	src: 	url('../fonts/Gilroy/Gilroy-LightItalic.woff2') format('woff2'),
			url('../fonts/Gilroy/Gilroy-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Gilroy';
	src: 	url('../fonts/Gilroy/Gilroy-Heavy.woff2') format('woff2'),
			url('../fonts/Gilroy/Gilroy-Heavy.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy';
	src: 	url('../fonts/Gilroy/Gilroy-MediumItalic.woff2') format('woff2'),
			url('../fonts/Gilroy/Gilroy-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Gilroy';
	src: 	url('../fonts/Gilroy/Gilroy-Thin.woff2') format('woff2'),
			url('../fonts/Gilroy/Gilroy-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}