#login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    width: 100%;
    max-width: 400px;
    border: 2px solid #fff;
    border-radius: 6px;
}
  
.auth0-lock-header-bg-blur {
    display: none !important;
}
  
.auth0-lock-header-bg,
.auth0-lock-header-bg-solid,
.auth0-lock-header,
.auth0-lock-cred-pane {
    background-color: #fff !important;
}
  
.auth0-lock-widget {
    overflow-x: visible !important;
    width: 100% !important;
}
  
.auth0-lock-content-wrapper  {
    background-color: white !important;
    flex-grow: initial !important;
}
  
.auth0-lock-header-logo {
    height: 40px !important;

    &.centered{
        margin-top: 0px;
    }
}
  
.auth0-lock-content,
.auth0-lock-tabs,
.auth0-lock-body-content,
.auth0-lock-view-content,
.auth0-global-message.auth0-global-message-success,
.auth0-global-message.auth0-global-message-error,
.auth0-lock-content-wrapper {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}
  
  
  
.auth0-global-message{
    &.auth0-global-message-success, &.auth0-global-message-error{
        font-size: 12px !important;
        border-radius: 5px !important;
    }
}
  
.auth0-global-message.auth0-global-message-success {
    background: #68d7b0 !important;
    color: #fff !important;
}
  
.auth0-global-message.auth0-global-message-error {
    background: #ff5451 !important;
    color: #fff !important;
}
  
.auth0-lock-cred-pane-internal-wrapper {
    height: auto !important;
}
  
.auth0-lock.auth0-lock .auth0-lock-terms a{
    color: #475cff;
}
  
.auth0-lock.auth0-lock .auth0-lock-name{
    font-size: 17px;
}
  
.auth0-lock.auth0-lock .auth0-lock-confirmation-content > p{
    margin-top: 6px;
}
  
.auth0-lock.auth0-lock .auth0-lock-content-body-wrapper{
    overflow: hidden;
    padding: 0 1rem;
}
  
.in-iframe .auth0-lock.auth0-lock .auth0-lock-center{
    padding: 0 !important;
}
  
#login-container .auth0-lock.auth0-lock{
  
    .auth0-lock-center{
        padding: 0 !important;
    }
  
    .auth0-lock-terms{
        background-color: transparent;
        color: #bfcad8;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.75;
        margin-bottom: 30px;
        font-family: 'Gilroy';
    }
  
    .auth0-lock-pane-separator{
        padding-top: 16px;

        & + div > p {
            margin-bottom: 16px;
            & > span{
                font-size: 16px;
                font-weight: normal;
                color: #261f70;
                line-height: 1.75;
                text-transform: capitalize;
            }
        }
    }
  
    .auth0-lock-submit {
        background: #4f43df !important;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
  
        font-size: 16px;
        font-weight: 500;
        padding: 14px 20px; 
        border-radius: 4px;
        width: 100%;
  
        &[disabled]{
            background-color: #9b9b9b !important;
        }
  
        &:hover:not([disabled]){
            background: #3b2ddb !important;
            span{
                transform: none;
            }
        }
    }
  
    .auth0-lock-form{
        padding-right: 0;
        padding-left: 0;
  
        p{
            font-family: "Gilroy";
            color: #323b44;
            font-size: 16px;
            font-weight: normal;
            line-height: normal;
        }
  
        .auth0-lock-alternative{
            text-align: left;
            margin-top: 8px;
  
            .auth0-lock-alternative-link{
                color: #8292a5;
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
  
    .auth0-lock-input-block{
        margin-bottom: 16px;
        &:last-child{
            margin-bottom: 0px;
        }
    }
  
    .auth0-lock-input-block.auth0-lock-input-show-password{
        margin-bottom: 0px;

        svg{
            display: none;
        }
    }
  
    .auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon  {
        padding-left: 0;
        border-radius: 4px; 
        border: 1px solid #bfcad8;
  
        .auth0-lock-input{
            border-radius: 4px;
            height: 48px;
            font-size: 16px;
            font-weight: 400;
  
            &::placeholder {
                color: #d0d2dd;
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
  
    .auth0-lock-social-button:hover:not([disabled]), .auth0-lock-social-button:focus:not([disabled]){
        .auth0-lock-social-button-icon{
            background-color: transparent !important;
        }

        .auth0-lock-social-button-text{
            background-color: #eff2f7 !important;
        }
    }
  
    .auth0-lock-last-login-pane{
        .auth0-lock-social-button{
            .auth0-lock-social-button-icon{
                display: none;
            }

            .auth0-lock-social-button-text{
                padding-left: 15px;
            }
        }
    }
    
    .auth0-lock-social-button[data-provider^=google].auth0-lock-social-big-button{
        border-radius: 4px;
        border: solid 2px #4f43df;
        background-color: #FFF;
        height: 48px;
  
        .auth0-lock-social-button-text{
            color: #4f43df !important;
            font-size: 16px;
            font-weight: 500;
            line-height: 46px;
            letter-spacing: -0.15px;
            text-align: center;
            padding-right: 40px;
        }
  
        .auth0-lock-social-button-icon{
          background-size: auto 16px;
          background-position: center;
          height: 46px;
          width: 42px !important;
        }
    }
  
    .auth0-loading-container .auth0-loading {
        top: -2px;
        left: 46%;
        width: 20px;
        height: 20px;
        border-color: rgba(255,255,255,0.8) rgba(255,255,255,0.8) rgba(255,255,255,0.2) rgba(255,255,255,0.2);
    }
  
    .auth0-lock-submit .auth0-label-submit{
        height: 20px;
        line-height: 20px;
        text-transform: initial;
        letter-spacing: -0.15px;
        span{
            display: none;
        }
    }
  
    .auth0-lock-close-button, .auth0-lock-back-button{
        height: 16px;
        width: 16px;
  
        svg{
            & *{
                stroke: #4f43df;
                stroke-width: 3;
            }
        }
    }
}