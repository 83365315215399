.btn {
    &.btn-icon {
        padding: 0.715rem 0.736rem;

        &.btn-sm {
            padding: 0.5rem;
        }

        &.btn-lg {
            padding: 1rem 1.1rem;
        }
    }
}