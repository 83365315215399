@mixin bg-opacity($color, $opacity: 1) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

@function color-function($color, $type) {
    @if map-has-key($colors, $color) {
      $curr_color: map-get($colors, $color);
  
      @if map-has-key($curr_color, $type) {
        @return map-get($curr_color, $type);
      }
    }
  
    @return null;
}

$white: (
    "base": #ffffff
);
$black: (
    "base": #000000
);
$dark: (
    "lighten-5": lighten($dark, 25%),
    "lighten-4": lighten($dark, 20%),
    "lighten-3": lighten($dark, 15%),
    "lighten-2": lighten($dark, 10%),
    "lighten-1": lighten($dark, 5%),
    "base": $dark,
    "darken-1": darken($dark, 5%),
    "darken-2": darken($dark, 10%),
    "darken-3": darken($dark, 15%),
    "darken-4": darken($dark, 20%)
);
$light: (
    "lighten-5": lighten($light, 25%),
    "lighten-4": lighten($light, 20%),
    "lighten-3": lighten($light, 15%),
    "lighten-2": lighten($light, 10%),
    "lighten-1": lighten($light, 5%),
    "base": $light,
    "darken-1": darken($light, 5%),
    "darken-2": darken($light, 10%),
    "darken-3": darken($light, 15%),
    "darken-4": darken($light, 20%)
);
$primary: (
    "lighten-5": lighten($primary, 25%),
    "lighten-4": lighten($primary, 20%),
    "lighten-3": lighten($primary, 15%),
    "lighten-2": lighten($primary, 10%),
    "lighten-1": lighten($primary, 5%),
    "base": $primary,
    "darken-1": darken($primary, 5%),
    "darken-2": darken($primary, 10%),
    "darken-3": darken($primary, 15%),
    "darken-4": darken($primary, 20%)
);
$secondary: (
    "lighten-5": lighten($secondary, 25%),
    "lighten-4": lighten($secondary, 20%),
    "lighten-3": lighten($secondary, 15%),
    "lighten-2": lighten($secondary, 10%),
    "lighten-1": lighten($secondary, 5%),
    "base": $secondary,
    "darken-1": darken($secondary, 5%),
    "darken-2": darken($secondary, 10%),
    "darken-3": darken($secondary, 15%),
    "darken-4": darken($secondary, 20%)
);
$success: (
    "lighten-5": lighten($success, 25%),
    "lighten-4": lighten($success, 20%),
    "lighten-3": lighten($success, 15%),
    "lighten-2": lighten($success, 10%),
    "lighten-1": lighten($success, 5%),
    "base": $success,
    "darken-1": darken($success, 5%),
    "darken-2": darken($success, 10%),
    "darken-3": darken($success, 15%),
    "darken-4": darken($success, 20%)
);
$info: (
    "lighten-5": lighten($info, 25%),
    "lighten-4": lighten($info, 20%),
    "lighten-3": lighten($info, 15%),
    "lighten-2": lighten($info, 10%),
    "lighten-1": lighten($info, 5%),
    "base": $info,
    "darken-1": darken($info, 5%),
    "darken-2": darken($info, 10%),
    "darken-3": darken($info, 15%),
    "darken-4": darken($info, 20%)
);
$warning: (
    "lighten-5": lighten($warning, 25%),
    "lighten-4": lighten($warning, 20%),
    "lighten-3": lighten($warning, 15%),
    "lighten-2": lighten($warning, 10%),
    "lighten-1": lighten($warning, 5%),
    "base": $warning,
    "darken-1": darken($warning, 5%),
    "darken-2": darken($warning, 10%),
    "darken-3": darken($warning, 15%),
    "darken-4": darken($warning, 20%)
);
$danger: (
    "lighten-5": lighten($danger, 25%),
    "lighten-4": lighten($danger, 20%),
    "lighten-3": lighten($danger, 15%),
    "lighten-2": lighten($danger, 10%),
    "lighten-1": lighten($danger, 5%),
    "base": $danger,
    "darken-1": darken($danger, 5%),
    "darken-2": darken($danger, 10%),
    "darken-3": darken($danger, 15%),
    "darken-4": darken($danger, 20%)
);


$colors: (
    "white": $white,
    "black": $black,
    "dark": $dark,
    "light": $light,
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger
);

@each $color_name, $color in $colors {
    @each $color_type, $color_value in $color {
        @if $color_type== "base" {
            
            .#{$color_name} {
                color: $color_value !important;
            }
    
            // Border colors
            .border-#{$color_name} {
                border-width: 1px;
                border-style: solid;
                border-color: $color_value !important;
            }
            .border-top-#{$color_name} {
                border-top: 1px solid $color_value;
            }
            .border-bottom-#{$color_name} {
                border-bottom: 1px solid $color_value;
            }
            .border-left-#{$color_name} {
                border-left: 1px solid $color_value;
            }
            .border-right-#{$color_name} {
                border-right: 1px solid $color_value;
            }


            // Basic buttons
            .btn-#{$color_name} {
                border-color: color-function($color_name, "darken-2") !important;
                background-color: $color_value !important;
                @if $color_name == "light" {
                    color: $body-color !important;
                } @else {
                    color: #fff !important;
                }

                &:hover {
                    border-color: color-function($color_name, "darken-1") !important;
                    background-color: color-function($color_name, "darken-1") !important;
                    @if $color_name == "light" {
                        color: $body-color !important;
                    } @else {
                        color: #fff !important;
                    }
                }

                &:focus,
                &:active {
                    background-color: color-function($color_name, "darken-2") !important;
                    @if $color_name == "light" {
                        color: $body-color !important;
                    } @else {
                        color: #fff !important;
                    }
                }
            }

            // Outline buttons
            .btn-outline-#{$color_name} {
                border: 1px solid $color_value;
                color: $color_value;

                &:hover,
                &:focus,
                &:active{
                    @if $color_name == "light" {
                        color: $body-color !important;
                    } @else {
                        color: #fff !important;
                    }
                    background-color: $color_value !important;
                }
            }

            // Flat buttons
            .btn-flat-#{$color-name} {
                background-color: rgba($color-value, 0.1);
                color: $color-value;

                &:hover {
                    color: $color-value;
                    background-color: rgba($color-value, 0.15);
                }

                &:active,
                &:focus {
                    background-color: rgba($color-value, 0.2) !important;
                    color: $color-value !important;
                }
            }
        } @else {
            .#{$color_name}.#{$color_type} {
              color: $color_value !important;
            }
      
            .bg-#{$color_name}.bg-#{$color_type} {
              background-color: $color_value !important;
            }
        }
    }
}
  