@import "./layout-variables.scss";

html {
    font-size: $base-font-size;
    height: 100%;
    letter-spacing: 0.01rem;
    overflow: hidden;

    body {
        height: 100%;
        overflow: hidden;

        #root{
            overflow: hidden;
            display: flex;
            flex-direction: column;
            height: 100vh;


            .content-wrapper{
                display: flex;
                flex: 1;
                overflow: hidden;
            }
            
            .content{
                padding: #{$content-padding};
                overflow: auto;
                width: 100%;
            }
        }
    }
}